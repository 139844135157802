import React from 'react';
import { CircularProgress } from '@mui/material';
import CommonButton from '../common/CommonButton';
import { Link } from 'react-router-dom';
import { REQUEST_STATUS } from '../../consts/requestStatus';
import useRequestEval from '../../hooks/useRequestEval';

function TableActionButton({rowStatus, rowVideoId, refreshTable}) {
    const { handleEvaluate, evalRequestStatus } = useRequestEval(rowVideoId);
    
    if (rowStatus === 'Evaluated') {
        return (
            <Link to={`/results/${rowVideoId}`} style={{ textDecoration: 'none' }}>
                <CommonButton>View Result</CommonButton>
            </Link>
        )
    } else {
        if (evalRequestStatus === REQUEST_STATUS.LOADING) {
            return (
                <CircularProgress/>
            )
        } else {
            return (
                <CommonButton 
                    disabled={rowStatus !== 'Uploaded'}
                    onClick={async () => {
                        await handleEvaluate();
                        refreshTable();
                    }}
                >
                    Evaluate
                </CommonButton>
            )
        }
        
    }
}

export default TableActionButton;