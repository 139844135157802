import React, { createContext, useState, useEffect } from "react";
import useRequestStatusTable from "../hooks/useRequestStatusTable";
import { REQUEST_STATUS } from "../consts/requestStatus";

export const StatusTableContext = createContext();

function StatusTableProvider({ 
    children, 
    startingNumberRows = 10,
    startingPage = 1,
    startingStatus = [],
    startingOrder = {orderBy: "Id", mode: "DESC"},
    startingTextSearch = {"Id": "", "Name": "", "Username": "", "Place": "", "StreetName": ""},
    startingDateSearch = {"UploadTime": {"startDate":"","endDate":""}, "InspectionDate": {"startDate":"","endDate":""}}
 }) {
    const [nRows, setNRows] = useState(startingNumberRows);
    const [page, setPage] = useState(startingPage);
    const [selectedStatuses, setSelectedStatuses] = useState(startingStatus);
    const [ tableOrder, setTableOrder ] = useState(startingOrder);
    const [ textSearch, setTextSearch ] = useState(startingTextSearch);
    const [ dateSearch, setDateSearch ] = useState(startingDateSearch);
    const [ searchDisabled, setSearchDisabled ] = useState(false);
    
    const { data, totalRows, refreshTable, tableRequestStatus, statusOptions } = useRequestStatusTable(nRows, page, setPage, selectedStatuses, tableOrder, textSearch, dateSearch);
    const resetSearch = () => {
        setSelectedStatuses(startingStatus);
        setTableOrder(startingOrder);
        setTextSearch(startingTextSearch);
        setDateSearch(startingDateSearch);
        setSearchDisabled(false);
        refreshTable();
    }
    useEffect(() => {
        if (tableRequestStatus === REQUEST_STATUS.LOADING) {
            setSearchDisabled(true);
        } else {
            setSearchDisabled(false);
        }
    }, [tableRequestStatus])
    
    return (
        <StatusTableContext.Provider
            value={{ 
                nRows, setNRows,
                page, setPage,
                selectedStatuses, setSelectedStatuses,
                statusOptions,
                data, totalRows, refreshTable, tableRequestStatus,
                tableOrder, setTableOrder,
                textSearch, setTextSearch,
                dateSearch, setDateSearch,
                searchDisabled, setSearchDisabled,
                resetSearch
            }}
        >
            { children }
        </StatusTableContext.Provider>
    )
}

export { StatusTableProvider };