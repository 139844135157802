import React from 'react';
import { Box, Divider } from '@mui/material';
import MetadataFieldFile from '../Metadata/MetadataFieldFile';
import MetadataFieldManual from '../Metadata/MetadataFieldManual';
import MetadataList from '../Metadata/MetadataList';
import MetadataInstructions from '../Metadata/MetadataInstructions';
import NameChangeField from './NameChangeField';

const MetadataStep = ({
    defaultName, 
    initialName, 
    onChangeName, 
    metadata, 
    setMetadata, 
    metadataImported, 
    setMetadataImported, 
    setMetadataConflicts,
    videoNameError, setVideoNameError
}) => {
  return (
        <div>
            <Box>
                <Divider />

                <NameChangeField
                    initialName={initialName}
                    defaultName={defaultName}
                    onChangeName={onChangeName}
                    videoNameError={videoNameError}
                    setVideoNameError={setVideoNameError}
                />

                <Divider />
                
                <MetadataInstructions />

                <MetadataFieldManual
                    metadata={metadata} 
                    setMetadata={setMetadata}
                />
                <MetadataFieldFile
                    metadataImported={metadataImported}
                    setMetadataImported={setMetadataImported}
                />
                <Divider />
                <MetadataList 
                    metadata={metadata} onMetadataChange={setMetadata}
                    metadataImported={metadataImported} onMetadataImportedChange={setMetadataImported}
                    onMetadataConflictsChange={setMetadataConflicts}
                />
            </Box>
        </div>
    )
  
};


export default MetadataStep;