const fetchStatusTable = async (nRows, page, selectedStatuses, tableOrder, textSearch, dateSearch) => {
    const requestUrl = process.env.REACT_APP_GET_VIDEOS_URL;
    const formData = new FormData();
    formData.append('statuses', JSON.stringify(selectedStatuses));
    formData.append('orderBy', JSON.stringify(tableOrder));
    formData.append('dateRange', JSON.stringify(dateSearch));
    const trimmedTextSearch = Object.keys(textSearch).reduce((acc, key) => {
        acc[key] = textSearch[key].trim();
        return acc;
    }, {});
    formData.append('textSearch', JSON.stringify(trimmedTextSearch));
    const response = await fetch(
        `${requestUrl}?n_rows=${nRows}&page=${page}`,
        {
            method: 'POST',
            body: formData,
        }
    );
    return response;  
}

export default fetchStatusTable;