import React from 'react'
import { Box, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { metadataTypes } from '../../consts/metadataTypes';

function MetadataManualInputReadOnly({
    metadataName,
    metadataType,
    metadataValue,
}){
    return (
        <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                <TextField
                    label="Label"
                    variant="outlined"
                    value={metadataName}
                    sx={{mt: '5px', mb: '5px'}}
                    inputProps={{ maxLength: 255 }}
                    slotProps={{
                        input: {
                          readOnly: true,
                        },
                    }}
                />
                <FormControl variant="outlined" sx={{ minWidth: 120, mt: '5px', mb: '5px' }}>
                    <InputLabel>Type</InputLabel>
                    <Select
                        label="Type"
                        value={metadataType}
                        inputProps={{ readOnly: true }}
                    >
                        {metadataTypes.map((type, index) => (
                            <MenuItem key={index} value={type}>{type}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Value"
                    variant="outlined"
                    value={metadataValue}
                    sx={{ mt: '5px', mb: '5px' }}
                    inputProps={{ maxLength: 255 }}
                    slotProps={{
                        input: {
                          readOnly: true,
                        },
                    }}
                />
        </Box>
    )
}

export default MetadataManualInputReadOnly;