import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import StatusFilter from './StatusFilter/StatusFilter';
import SearchButton from './SearchButton';
import SearchTextField from './SearchTextField';
import DateRangeField from './DateRangeField';
import { StatusTableContext } from '../../contexts/StatusTableContext';
import ResetSettingsButton from './ResetSettingsButton';

function TableSettings({ refreshTable }) {
    const { textSearch, dateSearch } = useContext(StatusTableContext);
    return (
        <Paper sx={{ mb: 2, p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <StatusFilter />
                {Object.keys(textSearch).map((key) => (
                    <SearchTextField key={key} columnLabel={key} />
                ))}
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {Object.keys(dateSearch).map((key) => (
                    <DateRangeField key={key} columnLabel={key} />
                ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ResetSettingsButton/>
                <SearchButton refreshTable={refreshTable} />
            </Box>
        </Paper>
    )
}

export default TableSettings;