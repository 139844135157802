export const formatDateTime = (dateTimeString) => {
    // Ensure the input matches the expected format
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    if (!dateTimeRegex.test(dateTimeString)) {
      console.error("Invalid date-time format. Expected format: YYYY-MM-DD HH:MM:SS");
      return null;
    }
    const [datePart, timePart] = dateTimeString.split(" ");
    const [year, month, day] = datePart.split("-");
    const formattedDate = `${day}-${month}-${year}`;
    return `${formattedDate} ${timePart}`;
}

export const formatDate = (dateString) => {
    if (dateString === null) {
        return null;
    }
    // Ensure the input matches the expected format
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(dateString)) {
        console.error("Invalid date format. Expected format: YYYY-MM-DD");
        return null;
    }
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
}