import React, {useContext} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { StatusTableContext } from '../../../contexts/StatusTableContext';

function StatusFilterSelect() {
    const { statusOptions, selectedStatuses, setSelectedStatuses } = useContext(StatusTableContext);
    const allStatuses = statusOptions.flatMap(({ statuses }) => statuses);
    const handleCheckboxClick = (status) => {
        if (selectedStatuses.includes(status)) {
            setSelectedStatuses(selectedStatuses.filter((s) => s !== status));
        } else {
            setSelectedStatuses([...selectedStatuses, status]);
        }
    };
    const handleAllClick = () => {
        if (selectedStatuses.length === allStatuses.length) {
            // Deselect all if all are selected
            setSelectedStatuses([]);
        } else {
            // Select all
            setSelectedStatuses(allStatuses);
        }
    };

    const handleGroupClick = (groupStatuses) => {
        const allSelected = groupStatuses.every((status) => selectedStatuses.includes(status));

        if (allSelected) {
            // Deselect all statuses in the group
            setSelectedStatuses(selectedStatuses.filter((status) => !groupStatuses.includes(status)));
        } else {
            // Select all statuses in the group
            setSelectedStatuses([...new Set([...selectedStatuses, ...groupStatuses])]);
        }
    };
    return (
        <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
        <InputLabel id="status_select_label">Status</InputLabel>
        <Select
            labelId="status_select_label"
            id="status_select"
            multiple
            value={selectedStatuses}
            renderValue={(selected) => selected.join(', ')}
        >
            <MenuItem onClick={handleAllClick}>
                <Checkbox
                    checked={selectedStatuses.length === allStatuses.length && allStatuses.length > 0}
                />
                <ListItemText primary="All" />
            </MenuItem>
            {statusOptions.map(({ group, statuses }) => (
                <React.Fragment key={group}>
                    <MenuItem onClick={() => handleGroupClick(statuses)}>
                        <Checkbox
                            checked={statuses.every((status) => selectedStatuses.includes(status))}
                        />
                        <ListItemText primary={group} />
                    </MenuItem>
                    {statuses.map((status) => (
                        <MenuItem key={status} onClick={() => handleCheckboxClick(status)} sx={{ pl: 4 }}>
                            <Checkbox checked={selectedStatuses.includes(status)} />
                            <ListItemText primary={status} />
                        </MenuItem>
                    ))}
                </React.Fragment>
            ))}
        </Select>
        </FormControl>
    )
}

export default StatusFilterSelect