import React from 'react';
import { Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MetadataManualInputReadOnly from './MetadataManualInputReadOnly';

function CoordinatesInstruction() {
    return (
        <Accordion sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="body1">How to Add Coordinates</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    If you want to add coordinates of the start point and end point of the inspection to the metadata, please use the following format:
                </Typography>
                <MetadataManualInputReadOnly
                    metadataName={'StartPointLocation'}
                    metadataType={'text'}
                    metadataValue={'123456.78 876543.21'}
                />
                <MetadataManualInputReadOnly
                    metadataName={'EndPointLocation'}
                    metadataType={'text'}
                    metadataValue={'876543.21 123456.78'}
                />
                <Typography variant="body1" sx={{ mb: 2 }}>
                    The coordinates should follow the GML format with srsDimension="2" and srsName="Netherlands-RD.
                </Typography>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

export default CoordinatesInstruction;