import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { StatusTableContext } from '../../contexts/StatusTableContext';

function ResetSettingsButton() {
    const { searchDisabled, resetSearch } = useContext(StatusTableContext);
    return (
        <Button
            variant="outlined"
            onClick={resetSearch} 
            sx={{ m: 1 }}
            disabled={searchDisabled}
        >
            Reset
        </Button>
    )
}

export default ResetSettingsButton;