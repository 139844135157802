import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useRequestDetections from '../hooks/useRequestDetections'
import VideoDetections from '../components/VideoDetections/VideoDetections';
import InfoTabs from "../components/VideoInfoTabs/InfoTabs";
import { MetadataProvider } from '../contexts/MetadataContext';


function VideoPage() {
    const { videoId } = useParams();
    const [imageLocation, setImageLocation] = useState(null);
    const [nRows, setNRows] = useState(10);  // Amount of rows to display. Default to 10 rows
    const [page, setPage] = useState(1);   // Page number. Default to 1.
    const {
        videoInfo,
        videoName,
        labelsStat,
        monitoring,
        data,
        totalRows,
        labels,
        selectedLabel, setSelectedLabel,
        fpsValue,
        videoWithPredictions,
        videoEvaluated,
        dataRequestStatus,
        // inspectionCoordinates
    } = useRequestDetections(videoId, nRows, page, setImageLocation);
    return (
        <div>
        <MetadataProvider videoId={videoId}>
            <InfoTabs
                videoName={videoName}
                videoInfo={videoInfo}
                labelsStat={labelsStat}
                monitoring={monitoring}
                setSelectedLabel={setSelectedLabel}
                videoWithPredictions={videoWithPredictions}
                videoEvaluated={videoEvaluated}
                dataRequestStatus={dataRequestStatus}
                // inspectionCoordinates={inspectionCoordinates}
            />
        </MetadataProvider>
        <VideoDetections
            data={data}
            nRows={nRows} setNRows={setNRows}
            totalRows={totalRows}
            page={page} setPage={setPage}
            labels={labels}
            selectedLabel={selectedLabel} setSelectedLabel={setSelectedLabel}
            fpsValue={fpsValue}
            imageLocation={imageLocation} setImageLocation={setImageLocation}
        />
        </div>
    )
}
export default VideoPage;