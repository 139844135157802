import { useState, useEffect, useCallback } from 'react';
import { REQUEST_STATUS } from '../consts/requestStatus';
import fetchStatusTable from '../services/fetchStatusTable';


function useRequestStatusTable( nRows, page, setPage, selectedStatuses, tableOrder, textSearch, dateSearch) {
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [statusOptions, setStatusOptions] = useState([]);
    const [tableRequestStatus, setTableRequestStatus] = useState(REQUEST_STATUS.LOADING);


    const refreshTable = useCallback(async () => {
        setTableRequestStatus(REQUEST_STATUS.LOADING);
        try {
            const response = await fetchStatusTable(nRows, page, selectedStatuses, tableOrder, textSearch, dateSearch);
            const resultString = await response.text();
            const resultArray = JSON.parse(resultString);
            setTotalRows(resultArray.total_rows)
          // Check if the current page is beyond the updated total pages
            if (page > resultArray.total_pages) {
                setPage(1); // Switch to page 1
            } else {
                setData(resultArray.data);
            }
            setData(resultArray.data);
            const flattenedStatusOptions = Object.entries(resultArray.statuses).map(([group, statuses]) => ({
                group,
                statuses,
            }));
            setStatusOptions(flattenedStatusOptions);
            setTableRequestStatus(REQUEST_STATUS.SUCCESS);
            
        } catch (error) {
            console.error('Error fetching data:', error);
            setTableRequestStatus(REQUEST_STATUS.FAILURE);
        }
    }, [nRows, page, selectedStatuses, setPage, tableOrder, textSearch, dateSearch]);


    useEffect(() => {
        refreshTable();
    }, [nRows, page, tableOrder])

    return {
        data,
        totalRows,
        refreshTable,
        tableRequestStatus,
        statusOptions
    }
}

export default useRequestStatusTable;