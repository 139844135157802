import { useContext } from 'react';
import 'leaflet/dist/leaflet.css'
import { MapContainer, TileLayer, Polyline } from 'react-leaflet'
import { MetadataContext } from '../../contexts/MetadataContext';
import InspectionMapPlaceholder from './InspectionMapPlaceholder';
import DataErrorCard from '../common/DataErrorCard';
import EmptyMapCard from './EmptyMapCard';
import { calculateCenter } from '../../utils/mapUtils';

const mapLineOptions = { color: '#9c27b0', weight: 10, opacity: 0.7 }

function InspectionMap() {
    const { inspectionCoordinates } = useContext(MetadataContext);
    if (inspectionCoordinates) {
        if (inspectionCoordinates.length > 0) {
            const center = calculateCenter(inspectionCoordinates);
            
            return (
                <MapContainer
                center={center} 
                zoom={17}
                style={{ height: '280px', width: '40vw'}}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Polyline pathOptions={mapLineOptions} positions={inspectionCoordinates} />
            </MapContainer>
            )
        } else {
            return (
            <InspectionMapPlaceholder>
                <EmptyMapCard/>
            </InspectionMapPlaceholder>
            );
        }
    } else {
        return (
        <InspectionMapPlaceholder>
            <DataErrorCard transparent={true}/>
        </InspectionMapPlaceholder>
    );
    }
}

export default InspectionMap;