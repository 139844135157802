import CommonButton from '../common/CommonButton';


function LeftStepperButton({activeStep, handleCancel, handleBack, handleBackMetadataStep, isDisabled}) {
    switch (activeStep) {
        case 0:
            return (
            <CommonButton 
                onClick={handleCancel}
                variant="contained"
                disabled={isDisabled}
            > 
                Cancel 
            </CommonButton>
            );
        case 1:
            return (
                <CommonButton 
                onClick={handleBackMetadataStep}
                variant="contained"
                disabled={isDisabled}
            > 
                Back 
            </CommonButton>
            )
        case 2:
            return null;
        default:
            return (
                <CommonButton 
                    onClick={handleBack}
                    variant="contained"
                > 
                Back 
                </CommonButton>
            ) 
    }
}

export default LeftStepperButton;