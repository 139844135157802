import React, { useContext } from 'react';
import { StatusTableContext } from '../../../contexts/StatusTableContext';
import { REQUEST_STATUS } from '../../../consts/requestStatus';
import StatusFilterError from './StatusFilterError';
import StatusFilterLoading from './StatusFilterLoading';
import StatusFilterSelect from './StatusFilterSelect';

function StatusFilter() {
    const { statusOptions, tableRequestStatus } = useContext(StatusTableContext);
    if ((!Array.isArray(statusOptions) || statusOptions.length === 0) && tableRequestStatus === REQUEST_STATUS.LOADING) {
        return (
            <StatusFilterLoading />
        )
    }
    if (tableRequestStatus === REQUEST_STATUS.FAILURE) {
        return (
            <StatusFilterError />
        )
    }
    return (
        <StatusFilterSelect />
    )

}

export default StatusFilter;