import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';

function StatusFilterError() {
    return (
        <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
            <InputLabel id="status_select_label_loading">Status</InputLabel>
            <Select
                labelId="status_select_label_loading"
                id="status_select_loading"
                value={''}
                label="Status"
            >
                <MenuItem disabled>
                    <Typography>Failed to load options, <br/> please try again later.</Typography>
                </MenuItem>
            
            </Select>
        </FormControl>
    )
}

export default StatusFilterError;