import React from 'react';
import { Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InstructionsManuallyImage from '../../asserts/metadata_add_manually.jpg';
import InstructionsFileImage from '../../asserts/metadata_add_file.jpg';
import InstructionsReviewImage from '../../asserts/metadata_review.jpg';
import CoordinatesInstruction from './CoordinatesInstruction';

function MetadataInstructions() {
    return (
        <>
        <Typography variant="body1" sx={{mt: 1, mb: 1}}>Metadata provides additional information about the video.</Typography>
        <Typography variant="body1" sx={{mt: 1, mb: 1}}>For example, you can use metadata fields like "Inspection Date", "Starting Point", or "Profile Type".</Typography>
        <Accordion sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="body1">How to Add Metadata</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    <b> Follow these steps to add metadata field manually:</b>
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    1. Enter metadata field information in the "Label" and "Type" fields.
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    2. After selecting the type of the metadata field, an input for the field value will appear. Enter the metadata field value.
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    3. Click the "+" button to add the metadata field.
                </Typography>
                
                <img 
                    src={InstructionsManuallyImage} 
                    alt="Screenshot" 
                    style={{ maxWidth: '50%', border: '1px solid #ccc' }} 
                />
                <Typography variant="body2" sx={{ mb: 2, mt: 1 }}>
                    <b>Follow these steps to add metadata via JSON file:</b>
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    1. Download example JSON file.
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    2. Using the format of the example, create a JSON file with the video metadata you want to add.
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    3. Upload the created JSON file.
                </Typography>

                <img 
                    src={InstructionsFileImage} 
                    alt="Screenshot" 
                    style={{ maxWidth: '50%', border: '1px solid #ccc' }} 
                />

                <Typography variant="body2" sx={{ mb: 2, mt: 1 }}>
                    <b>Review metadata:</b>
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    1. Review the added metadata fields in the list.
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    2. To delete a metadata field, click on the delete icon next to the metadata field.
                </Typography>
                <img 
                    src={InstructionsReviewImage} 
                    alt="Screenshot" 
                    style={{ maxWidth: '50%', border: '1px solid #ccc' }} 
                />
                </Box>
            </AccordionDetails>
        </Accordion>
        <CoordinatesInstruction />
        </>
    )
}

export default MetadataInstructions;