import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@mui/material';
import TableSettings from './TableSettings';
import CustomTable from '../common/CustomTable';
import TableActionButton from './TableActionButton';
import CustomTablePagination from '../common/CustomTablePagination';
import { StatusTableProvider, StatusTableContext } from '../../contexts/StatusTableContext';
import TableDeleteButton from './TableDeleteButton';
import { REQUEST_STATUS } from '../../consts/requestStatus';
import DataErrorCard from '../common/DataErrorCard';
import { formatDateTime, formatDate } from '../../utils/dateTimeUtils';

const StatusTableNoProvider = () => {
    const { nRows, setNRows, page, setPage, data, totalRows, refreshTable, tableRequestStatus, tableOrder, setTableOrder } = useContext(StatusTableContext);

    const columns = [
        { id: 'Id', label: 'Id', orderBy: true},
        { 
            id: 'Name', 
            label: 'Name',
            orderBy: true,
            render: (row) => (
                <Link
                    underline="hover"
                    component={RouterLink}
                    to={`/results/${row.Id}`}
                >
                    {row.Name}
                </Link>
            )
        },
        { 
            id: 'UploadTime', 
            label: 'Upload Time', 
            orderBy: true,
            render: (row) => (
                <>
                {formatDateTime(row.UploadTime)}
                </>
            )
        },
        { id: 'Status', label: 'Status', orderBy: true},
        { id: 'Username', label: 'Username', orderBy: true},
        { id: 'Place', label: 'Place', orderBy: true},
        { id: 'StreetName', label: 'Street Name', orderBy: true},
        { 
            id: 'InspectionDate', 
            label: 'Inspection Date', 
            orderBy: true,
            render: (row) => (
                <>
                {formatDate(row.InspectionDate)}
                </>
            )
        },
        { 
            id: 'Action', 
            label: 'Action', 
            render: (row) => (
                <TableActionButton
                    rowStatus={row.Status}
                    rowVideoId={row.VideoId}
                    refreshTable={refreshTable}
                />
            )
        },
        { 
            id: 'Delete', 
            label: 'Delete', 
            render: (row) => (
                <TableDeleteButton videoId={row.VideoId} videoName={row.Name} refreshTable={refreshTable}/>
            )
        }
    ];
    const tableMinWidth = 650
    if (tableRequestStatus === REQUEST_STATUS.FAILURE) {
        return (
            <DataErrorCard/>
        )
    }
    return (
        <div>
            <TableSettings
                refreshTable={refreshTable}
            />
            <Paper>
            <CustomTablePagination 
                totalRows={totalRows} 
                rowsPerPage={nRows}
                setRowsPerPage={setNRows}
                page={page}
                setPage={setPage}
            />
            {tableRequestStatus === REQUEST_STATUS.LOADING ? (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                    <CustomTable
                        columns={columns}
                        data={data}
                        rowKey="VideoId"
                        tableMinWidth={tableMinWidth}
                        // orderByHead={true}
                        tableOrder={tableOrder}
                        setTableOrder={setTableOrder}
                    />
                    </>
                )}
            <CustomTablePagination 
                totalRows={totalRows} 
                rowsPerPage={nRows}
                setRowsPerPage={setNRows}
                page={page}
                setPage={setPage}
            />
            </Paper>
        </div>
    );
};

function StatusTable() {
    return (
        <StatusTableProvider>
            <StatusTableNoProvider/>
        </StatusTableProvider>
    )
}

export default StatusTable;