import { metadataAllowedTypes, metadataSizeLimit } from '../consts/uploadStepperConsts';
import { metadataTypes } from '../consts/metadataTypes';


export const validateFileContent = (data) => {

    if (!Array.isArray(data)) {
        return {
            isValid: false,
            errors: ["The file content is not a valid JSON array."]
        };
    }
    const errors = [];
    // check if there are any duplicates in FieldLabels
    const labels = data.map(item => (item.FieldLabel))

    const duplicateLabels = labels.filter((label, index) => labels.indexOf(label) !== index);
    duplicateLabels.forEach((label) => {
        errors.push(`Error: Duplicate FieldLabel "${label}" found.`);
    });
    if (errors.length > 0) {
        errors.push('All FieldLabels must be unique.')
        return {
            isValid: false,
            errors,
        };
    }

    data.forEach((item, index) => {
        const { FieldLabel, FieldType, FieldValue } = item;

        // Check FieldLabel
        if (typeof FieldLabel !== 'string') {
            errors.push(`Error in field "${FieldLabel}": FieldLabel must be a string.`);
        } else if (FieldLabel.length > 224) {
            errors.push(`Error in field "${FieldLabel}": FieldLabel exceeds 224 characters.`);
        }

        // Check FieldType
        if (!metadataTypes.includes(FieldType)) {
            errors.push(`Error in field "${FieldLabel}": Invalid FieldType "${FieldType}". Must be one of ${metadataTypes.join(", ")}.`);
        }
        if (['StartPointLocation', 'EndPointLocation'].includes(FieldLabel)) {
            if (FieldType !== 'text') {
                errors.push(`Error in field "${FieldLabel}": FieldType for this field must be "text".`)
            }
            const floatPairRegex = /^\d+\.\d+ \d+\.\d+$/;
            if (!floatPairRegex.test(FieldValue)) {
                errors.push(`Error in field "${FieldLabel}": FieldValue must be two float coordinates in the format "876543.21 123456.78", separated by a space.`);
            }
        } else {
            // Check FieldValue based on FieldType
            switch (FieldType) {
                case "text":
                    if (typeof FieldValue !== "string") {
                        errors.push(`Error in field "${FieldLabel}": FieldValue must be a string for FieldType "text".`);
                    }
                    break;
                case "number":
                    if (typeof FieldValue !== "number") {
                        errors.push(`Error in field "${FieldLabel}": FieldValue must be a number for FieldType "number".`);
                    }
                    break;
                case "date":
                    if (!/^\d{4}-\d{2}-\d{2}$/.test(FieldValue)) {
                        errors.push(`Error in field "${FieldLabel}": FieldValue must be in YYYY-MM-DD format for FieldType "date".`);
                    } else {
                        // Validate the date value itself
                        const [year, month, day] = FieldValue.split('-').map(Number);
                        const date = new Date(year, month - 1, day);
                        if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
                            errors.push(`Error in field "${FieldLabel}": Invalid date value "${FieldValue}".`);
                        }
                    }
                    break;
                case "datetime":
                    if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(FieldValue)) {
                        errors.push(`Error in field "${FieldLabel}": FieldValue must be in YYYY-MM-DDTHH:MM format for FieldType "datetime".`);
                    } else {
                        // Validate the datetime value itself
                        const [datePart, timePart] = FieldValue.split('T');
                        const [year, month, day] = datePart.split('-').map(Number);
                        const [hour, minute] = timePart.split(':').map(Number);
                        const date = new Date(year, month - 1, day, hour, minute);
                        if (
                            date.getFullYear() !== year ||
                            date.getMonth() + 1 !== month ||
                            date.getDate() !== day ||
                            date.getHours() !== hour ||
                            date.getMinutes() !== minute
                        ) {
                            errors.push(`Error in field "${FieldLabel}": Invalid datetime value "${FieldValue}".`);
                        }
                    }
                    break;
                default:
                    errors.push(`Error in field "${FieldLabel}": Unknown FieldType "${FieldType}".`);
            }
        }
    });

    return {
        isValid: errors.length === 0,
        errors,
    };
};

export const validateFile = (file) => {
    if (!file) return { isValid: false, errors: ["No file selected."] };

    const fileMimeType = file.type;
    const fileSize = file.size;
    const isFormatValid = metadataAllowedTypes.includes(fileMimeType);
    const isSizeValid = fileSize <= metadataSizeLimit;

    const errors = [];
    if (!isFormatValid) errors.push(`Invalid file format. Allowed formats: ${metadataAllowedTypes.join(", ")}.`);
    if (!isSizeValid) errors.push(`File size exceeds the limit of ${metadataSizeLimit / (1024 * 1024)}MB.`);

    return { isValid: isFormatValid && isSizeValid, errors };
};

export const readFileAsText = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        reader.readAsText(file);
    });
};

export const validateMetadataValue = (newMetadataName, newMetadataType, newMetadataValue) => {
    if (['StartPointLocation', 'EndPointLocation'].includes(newMetadataName)) {
        const floatPairRegex = /^\d+\.\d+ \d+\.\d+$/;
        if (!floatPairRegex.test(newMetadataValue)) {
            return 'Value must be two float coordinates in the format "876543.21 123456.78", separated by a space.';
        }
    }
    if (newMetadataType === 'number' && isNaN(newMetadataValue)) {
        return 'Value must be a valid number.';
    }
    if (newMetadataType === 'date' && isNaN(Date.parse(newMetadataValue))) {
        return 'Value must be a valid date in YYYY-MM-DD format.';
    }
    if (newMetadataType === 'datetime' && isNaN(Date.parse(newMetadataValue))) {
        return 'Value must be a valid datetime in YYYY-MM-DDTHH:mm:ss format.';
    }
    return '';
};


export const isMetadataEqual = (arr1, arr2) => {
    const normalize = (arr) => arr.map(({ Id, ...rest }) => rest).sort((a, b) => a.FieldLabel.localeCompare(b.FieldLabel));
    const normalized1 = normalize(arr1);
    const normalized2 = normalize(arr2);
    if (normalized1.length !== normalized2.length) return false;
    return normalized1.every((item, index) => {
        const otherItem = normalized2[index];
        return item.FieldLabel === otherItem.FieldLabel &&
               item.FieldType === otherItem.FieldType &&
               item.FieldValue === otherItem.FieldValue;
    });
}