import React, { useContext, useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import { MetadataContext } from '../../contexts/MetadataContext';
import MetadataFieldFile from './MetadataFieldFile';
import MetadataFieldManual from './MetadataFieldManual';
import MetadataList from './MetadataList';
import updateMetadata from '../../services/updateMetadata';
import DataErrorCard from '../common/DataErrorCard';
import { isMetadataEqual } from '../../utils/metadataValidationUtils';
import ConfirmationDialog from '../common/ConfirmationDialog';
import CoordinatesInstruction from './CoordinatesInstruction';

function UpdateMetadataDialog({open, setClose}) {
    const { metadata, fetchMetadata, videoId } = useContext(MetadataContext);
    const [updatedMetadata, setUpdatedMetadata] = useState(metadata);
    const [metadataImported, setMetadataImported] = useState([]);
    const [metadataConflicts, setMetadataConflicts] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [closeDialogOpen, setCloseDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const metadataChanged = !isMetadataEqual([...updatedMetadata, ...metadataImported], metadata);

    const handleConfirmClose = () => {
        setCloseDialogOpen(false);
        setClose();
        setUpdatedMetadata(metadata);
        setUpdateError(false);
        setMetadataImported([]);
    }
    const handleClose = () => {
        if (metadataChanged) {
            setCloseDialogOpen(true);
        } else {
            handleConfirmClose();
        }
        
    }
    const onUpdate = async () => {
        try {
            await updateMetadata(
                updatedMetadata,
                metadataImported,
                videoId
            );
            fetchMetadata();
            setUpdateDialogOpen(false);
            handleConfirmClose();
        } catch (error) {
            console.error('Error updating metadata:', error);
            setUpdateDialogOpen(false);
            setUpdateError(true);
        }
    }
    const onClearMetadata = () => {
        setUpdatedMetadata([]);
        setMetadataImported([]);
    }
    useEffect(() => {
        setUpdatedMetadata(metadata);
    }, [metadata])
    
    return (
        <>
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>Update Metadata</DialogTitle>
            {updateError ? (
                <DataErrorCard text={'An error occurred while updating metadata. Please try again later or contact the support team.'}/>
            ): (
                <DialogContent>
                    <CoordinatesInstruction />
                    <MetadataFieldManual
                        metadata={updatedMetadata}
                        setMetadata={setUpdatedMetadata}
                    />
                    <MetadataFieldFile
                        metadataImported={metadataImported} 
                        setMetadataImported={setMetadataImported}
                    />
                    <Button onClick={onClearMetadata} color="primary">Clear Metadata</Button>
                    <MetadataList
                        metadata={updatedMetadata} onMetadataChange={setUpdatedMetadata}
                        metadataImported={metadataImported} onMetadataImportedChange={setMetadataImported}
                        onMetadataConflictsChange={setMetadataConflicts}
                        updateMode={true}
                    />
                </DialogContent>
            )}
            
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Button onClick={handleClose} color="primary" sx={{ml: 1}}>Cancel</Button>
                    <Button 
                        color="primary" 
                        onClick={() => setUpdateDialogOpen(true)}
                        sx={{mr: 1}}
                        disabled={metadataConflicts || !metadataChanged || updateError}
                    >
                        Update
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
        <ConfirmationDialog
            open={closeDialogOpen}
            onClose={() => setCloseDialogOpen(false)}
            onConfirm={handleConfirmClose}
            onDeny={() => setCloseDialogOpen(false)}
            question={'Are you sure you want to discard all changes? This action cannot be undone.'}
            title={'Discard Changes?'}
        />
        <ConfirmationDialog
            open={updateDialogOpen}
            onClose={() => setUpdateDialogOpen(false)}
            onConfirm={onUpdate}
            onDeny={() => setUpdateDialogOpen(false)}
            question={'Are you sure you want to update metadata?'}
            title={'Update Metadata?'}
        />
        </>
    )
}

export default UpdateMetadataDialog;