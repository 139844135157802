import React, { useContext } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { StatusTableContext } from '../../contexts/StatusTableContext';

function SearchButton({refreshTable}) {
    const { searchDisabled } = useContext(StatusTableContext);
    return (
        <Button
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={refreshTable} 
            sx={{ m: 1 }}
            disabled={searchDisabled}
        >
            Search
        </Button>
    )
}

export default SearchButton;