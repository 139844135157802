import React, { useContext, useState } from 'react';
import { TextField, FormControl, Box, Typography, FormHelperText  } from '@mui/material';
import { StatusTableContext } from '../../contexts/StatusTableContext';

function DateRangeField({ columnLabel }) {
    const { dateSearch, setDateSearch, setSearchDisabled } = useContext(StatusTableContext);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const camelCaseToSpaces = (str) => str.replace(/([A-Z])/g, ' $1').trim();

    const validateDates = (startDate, endDate) => {
        if (startDate && endDate) {
            if (new Date(startDate) > new Date(endDate)) {
                setError(true);
                setSearchDisabled(true);
                setErrorMessage('Start date must be before or equal to end date');
            } else {
                setError(false);
                setSearchDisabled(false);
                setErrorMessage('');
            }
        }
    };

    const handleStartDateChange = (event) => {
        const newStartDate = event.target.value;
        setDateSearch((prev) => ({
            ...prev,
            [columnLabel]: {
                ...prev[columnLabel],
                startDate: newStartDate,
            },
        }));
        validateDates(newStartDate, dateSearch[columnLabel]?.endDate);
    };

    const handleEndDateChange = (event) => {
        const newEndDate = event.target.value;
        setDateSearch((prev) => ({
            ...prev,
            [columnLabel]: {
                ...prev[columnLabel],
                endDate: newEndDate,
            },
        }));
        validateDates(dateSearch[columnLabel]?.startDate, newEndDate);
    };

    const currentRange = dateSearch[columnLabel] || { startDate: '', endDate: '' };

    return (
        <FormControl variant="standard" sx={{ m: 1, width: 400 }}>
            <Typography 
                variant="caption"
                sx={{
                    color: 'text.secondary',
                    whiteSpace: 'nowrap',
                    minWidth: '100px'
                }}
            >
                {camelCaseToSpaces(columnLabel)}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <TextField
                    label="From"
                    type="date"
                    value={currentRange.startDate}
                    onChange={handleStartDateChange}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    error={error}
                />
                <TextField
                    label="To"
                    type="date"
                    value={currentRange.endDate}
                    onChange={handleEndDateChange}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    error={error}
                />
            </Box>
            {error && (
                <FormHelperText error>{errorMessage}</FormHelperText>
            )}
        </FormControl>
    );
}

export default DateRangeField;
