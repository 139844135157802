import { Card, CardContent, Typography } from "@mui/material";

function DataErrorCard({
    maxWidth=600, 
    text='An error occurred while fetching data. Please try again later or contact the support team.',
    transparent=false
}) {
    return (
        <Card sx={{ 
            maxWidth: maxWidth, 
            margin: '20px auto', padding: '16px',
            backgroundColor: transparent ? 'rgba(255, 255, 255, 0.5)': 'white',
            borderColor: '#f44336', borderWidth: 1, borderStyle: 'solid' 
            }}>
        <CardContent>
            <Typography variant="h6" component="div" sx={{ color: '#d32f2f', fontWeight: 'bold' }}>
            Error
            </Typography>
            <Typography variant="body1" color="text.secondary">
                {text}
            </Typography>
        </CardContent>
        </Card>
    )
}

export default DataErrorCard;