import { useState } from 'react';
import { REQUEST_STATUS } from '../consts/requestStatus';

function useRequestEval( videoId ) {
    const [evalRequestStatus, setEvalRequestStatus] = useState(REQUEST_STATUS.SUCCESS);
    const handleEvaluate = async () => {
        setEvalRequestStatus(REQUEST_STATUS.LOADING);
        try {
            const requestUrl = process.env.REACT_APP_INVOKE_ENDPOINT_URL;
            const response = await fetch(`${requestUrl}?video_id=${videoId}`);
            if (!response.ok) {
                console.error('Error invoking endpoint:', response.statusText);
                setEvalRequestStatus(REQUEST_STATUS.FAILURE);
            }
            setEvalRequestStatus(REQUEST_STATUS.SUCCESS);
        } catch (error) {
            console.error('Error invoking endpoint:', error.message);
            setEvalRequestStatus(REQUEST_STATUS.FAILURE);
        };
    };
    return {
        handleEvaluate,
        evalRequestStatus
    }
}

export default useRequestEval;